<template>
  <div
    class="fixed z-40 top-0 bottom-0 left-0 right-0 h-full w-full bg-black bg-opacity-50 flex justify-end"
  >
    <div class="w-3/4 h-full cursor-pointer" @click="$emit('close')"></div>
    <div class="w-1/4 h-full bg-white rounded-l-2xl p-10 overflow-auto">
      <div class="flex items-center justify-between">
        <h1 class="text-black font-bold text-xl">New Incoming Repatriation</h1>
        <div
          class="bg-red-500 bg-opacity-30 p-5 rounded-full cursor-pointer"
          @click="$emit('close')"
        >
          <CloseIcon />
        </div>
      </div>
      <ValidationObserver v-slot="{ invalid }" v-if="step === 1">
        <form @submit.prevent="step = 2" class="text-left mt-10">
          <ValidationProvider
            name="Name of Deceased"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label for="item_name">Name of Deceased</label>
              <input
                id="name"
                type="text"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="Name of Deceased"
                v-model="repratriation.name_of_deceased"
              /><br />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Air Waybill Number"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label for="item_name">Air Waybill Number</label>
              <input
                id="name"
                type="number"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="Air Waybill Number"
                v-model="repratriation.air_waybill_number"
              /><br />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Airline"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label for="item_name">Airline</label>
              <input
                id="name"
                type="text"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="Airline"
                v-model="repratriation.airline"
              /><br />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Funeral Home"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label for="item_name">Funeral Home</label>
              <input
                id="name"
                type="number"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="₦100,000.00"
                v-model="repratriation.funeral_home"
              /><br />
            </div>
          </ValidationProvider>
          <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
            <div class="mb-5">
              <label for="item_name">I, (enter your name below)</label>
              <input
                id="name"
                type="text"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="James Bod"
                v-model="repratriation.name"
              /><br />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Address"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label for="item_name">Address, (enter your address below)</label>
              <input
                id="name"
                type="text"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="Address"
                v-model="repratriation.address"
              />
              <small>
                whose name and passport photograph appears on the attached means
                of identification, confirms collection of the human remains of
                the; Late <b>{{ repratriation.name_of_deceased }}</b>
              </small>
              <br />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Phone Number"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label for="item_name">Phone Number</label>
              <input
                id="name"
                type="number"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="Phone Number"
                v-model="repratriation.phone_number"
              /><br />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Date of Removal"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label for="item_name">Date of Removal</label>
              <input
                id="name"
                type="date"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="Date of Removal"
                v-model="repratriation.date_of_removal"
              /><br />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Admission"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label for="item_name">Admission</label>
              <select
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                v-model="repratriation.admission"
              >
                <option value="addmission">Yes</option>
                <option value="no addmission">No</option>
              </select>
              <br />
            </div>
          </ValidationProvider>
          <button
            class="w-full mt-12 text-white py-3 rounded-md bg-opacity-80 transition-all hover:bg-opacity-100 disabled:cursor-not-allowed"
            :class="invalid || submitting ? 'bg-gray-300' : 'bg-primary'"
            :disabled="invalid || submitting"
          >
            Continue
          </button>
        </form>
      </ValidationObserver>
      <div class="mt-7" v-else>
        <input
          type="file"
          ref="img"
          accept="image/*"
          class="hidden"
          v-on:change="uploadImg"
        />
        <div
          class="w-full h-36 text-center flex justify-center items-center bg-gray-200 rounded font-bold text-gray-500 text-lg cursor-pointer hover:bg-gray-300 transition"
          @click="$refs.img.click()"
        >
          Click to upload documents
        </div>
        <div class="mt-10" v-if="repratriation.documents.length > 0">
          <div
            class="flex justify-between items-center p-3 bg-gray-300 text-gray-700 mb-2 rounded"
            v-for="(img, index) in repratriation.documents"
            :key="index"
          >
            <b>
              {{ img.split("/").slice(-1)[0].split("_")[0] }}.{{
                img.split(".").pop()
              }}
            </b>
            <delete-icon class="cursor-pointer" @click.native="remove(img)" />
          </div>
        </div>
        <button
          @click="createRepratraition()"
          class="w-full mt-12 text-white py-3 rounded-md bg-opacity-80 transition-all hover:bg-opacity-100 disabled:cursor-not-allowed"
          :class="
            repratriation.documents.length < 2 || submitting
              ? 'bg-gray-300'
              : 'bg-primary'
          "
          :disabled="repratriation.documents.length < 2 || submitting"
        >
          {{
            repratriation.admission === "addmission"
              ? "Create and Continue to Admission"
              : "Create Repriatration"
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { createRepratriation } from "@/services/repriatration";
import { uploadToCloudinary } from "@/services/branch";
import DeleteIcon from "../../assets/icons/DeleteIcon.vue";

export default {
  name: "AddNewProduct",
  components: {
    CloseIcon: () => import("@/assets/icons/CloseIcon.vue"),
    DeleteIcon: () => import("@/assets/icons/DeleteIcon.vue"),
  },
  data() {
    DeleteIcon;
    return {
      repratriation: {
        name_of_deceased: "",
        air_waybill_number: "",
        airline: "",
        funeral_home: "",
        name: "",
        address: "",
        phone_number: "",
        signature: "True",
        date_of_removal: "",
        admission: "",
        documents: [],
      },
      submitting: false,
      step: 1,
    };
  },
  methods: {
    async createRepratraition() {
      this.submitting = true;
      let payload = this.repratriation;
      try {
        await createRepratriation(payload);
        if (this.repratriation.admission === "addmission") {
          this.$toast.info("Redirecting you to create new addmission");
          this.$router.push("/app/addmission/new");
        } else {
          this.$toast.success("New Create Repratriation");
          this.$emit("close");
        }
      } catch (error) {
        this.$toast.error(error.response.data.message || error);
        console.log(error);
      }
      this.submitting = false;
    },
    async uploadImg(e) {
      const img = e.target.files[0];

      let formData = new FormData();
      formData.append("upload_preset", "branchImages");
      formData.append("folder", "tos-project");
      formData.append("file", img);
      try {
        const response = await uploadToCloudinary(formData);
        this.repratriation.documents.push(response.data.url);
      } catch (error) {
        console.log(error);
      }
    },
    remove(img) {
      const index = this.repratriation.documents.indexOf(img);
      if (index > -1) {
        this.repratriation.documents.splice(index, 1);
      }
    },
  },
};
</script>

<style></style>
